import React from 'react'
import { graphql } from 'gatsby'
import injectSheet from 'react-jss'

export interface HomePageProps {
	data: {
		site: {
			siteMetadata: {
				title: string
			}
		}
	}
	classes: {
		component: string
		message: string
	}
}

class HomePage extends React.PureComponent<HomePageProps, {}> {
	static styles = {
		'@global': {
			body: {
				margin: 0,
				fontFamily:
					"-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif",
				'-webkit-font-smoothing': 'antialiased',
				'-moz-osx-font-smoothing': 'grayscale',
			},
		},

		component: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			height: '100vh',

			fontSize: '2em',
		},

		message: {
			backgroundColor: 'lightgrey',
			padding: '0.5em 1.5em',
			borderRadius: '4px',
		},
	}

	render() {
		const { classes, data } = this.props

		return (
			<div className={classes.component}>
				<p>patanaestate.com</p>

				<p className={classes.message}>Website coming soon</p>
			</div>
		)
	}
}

export default injectSheet(HomePage.styles)(HomePage)

export const query = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`
